import React, { useEffect, useReducer } from "react"
import { graphql, PageProps } from "gatsby"

import Layout from "../components/Layouts/mainPage"
import { NewHeader } from "../components/Headers/NewHeader"
import { Banner } from "../components/Banners/Pkw"
import { HowInstallmentWorks } from "../components/HowInstallmentWorks"
import { FormPKW } from "../components/FormPKW"
import { Questions } from "../components/Questions"
import { NewFooter } from "../components/NewFooter"

import { getPageData } from "../helpers/getPageData"

import { PageData } from "../interfaces/pageProps"
import { sendScrollEventToDataLayer } from "../helpers/sendScrollEventToDataLayerPkw"
import { QUESTIONS_FOR_INDEX_PKW } from "../components/Questions/helpers"
import { FourAdvantagesPoints } from "../components/FourAdvantagesPoints"

const titleForm = (
  <>
    Оформите карту сейчас – <br /> получите подарок!
  </>
)

const bannerTitle = `
  Карта «Халва» 
  и&nbsp;«HippoParking»
  Кэшбэк 20% при&nbsp;оплате&nbsp;парковки
`

const bannerDescription = `
  — Рассрочка до 24 месяцев
  <br />
  — До 17% на остаток по карте
  <br />— Обслуживание 0 ₽  
`

const IDS = ["formPkw", "textBottomCalculator", "partners", "questions", "footer"]

type ObserverType = {
  formPkw: boolean
  textBottomCalculator: boolean
  partners: boolean
  questions: boolean
  footer: boolean
}

function observerReducer(state: ObserverType, action: any) {
  if (IDS.includes(action.id)) {
    return { ...state, [action.id]: action.value }
  }
  throw new Error()
}

// Время для таймер(30мин)
const countDownTime = 1800000

const initialObserverState: ObserverType = {
  formPkw: false,
  textBottomCalculator: false,
  partners: false,
  questions: false,
  footer: false,
}

export default function Page({ data }: PageProps<PageData>) {
  const { ligal, noIndex } = getPageData(data)
  const [observerState, observerDispatch] = useReducer(observerReducer, initialObserverState)

  const callback = (entries: IntersectionObserverEntry[]) => {
    for (let index = 0; index < entries.length; index += 1) {
      const element = entries[index]
      const { id } = element.target
      observerDispatch({ id, value: element.isIntersecting })
    }
  }

  useEffect(() => {
    Object.keys(observerState).forEach((key) => {
      if (observerState[key as keyof typeof initialObserverState]) {
        sendScrollEventToDataLayer(key)
      }
    })
  }, [observerState])

  useEffect(() => {
    const isSupported =
      "IntersectionObserver" in window &&
      "IntersectionObserverEntry" in window &&
      "intersectionRatio" in window.IntersectionObserverEntry.prototype
    let observer: IntersectionObserver

    if (isSupported) {
      observer = new IntersectionObserver(callback)

      IDS.forEach((id) => {
        const element = document.getElementById(id)
        if (element) {
          observer.observe(element)
        }
      })
    }

    return () => {
      if (isSupported) {
        IDS.forEach((id) => {
          const element = document.getElementById(id)
          if (element) {
            observer.unobserve(element)
          }
        })
      }
    }
  }, [])

  return (
    <Layout noHeader noFooter noIndex={noIndex}>
      <NewHeader showHint={false} hasCTA countDownTime={countDownTime} />
      <Banner
        variantStyle="hippo"
        description={bannerDescription}
        title={bannerTitle}
        variant="hippo"
        orderNum="1"
      />
      <HowInstallmentWorks headTitle="Как получить 20% кэшбэка?" variant="hippo" orderNum="2" />
      <FormPKW
        hasBirth
        isGreenApprove
        dataLayerName="shortPersonalForm"
        title={titleForm}
        longTitle
        additionalEventInDataLayer
        longForm={false}
        countDownTime={countDownTime}
        withTimer
        progressBar
        formBtnText="Оформить карту"
        orderNum="3"
        fioPlaceHolder="Фамилия Имя Отчество"
      />
      <FourAdvantagesPoints
        title="Преимущества с Халвой:"
        withTitle
        variant="pkwRedesign"
        orderNum="4"
      />
      <Questions questionList={QUESTIONS_FOR_INDEX_PKW} additionalEventInDataLayer orderNum="5" />
      <NewFooter ligal={ligal} orderNum="6" />
    </Layout>
  )
}

export const query = graphql`
  query {
    allDynamicBanner {
      edges {
        node {
          name
          deskGatsbyImg {
            url
            childImageSharp {
              gatsbyImageData(
                layout: CONSTRAINED
                quality: 80
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
                breakpoints: [1920, 3840]
              )
            }
          }
          mobGatsbyImg {
            url
            childImageSharp {
              gatsbyImageData(
                layout: CONSTRAINED
                quality: 80
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
                breakpoints: [600, 1200]
              )
            }
          }
        }
      }
    }
    admin {
      page(url: "https://halvacard.ru/order/welcome-hippo/") {
        url
        ligal {
          text
        }
        notIndex
      }
    }
  }
`
